import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Axios from "axios";
import fetchRetry from "./components/util/fetchRetry";

import Menu from "./components/Menu";
import Pasaporte from "./components/Pasaporte";
import Anverso from "./components/Anverso";
import Reverso from "./components/Reverso";
import Facial from "./components/Facial";
import ResumenCredencial from "./components/ResumenCredencial";
import ResumenPasaporte from "./components/ResumenPasaporte";
import Resultado from "./components/Resultado";

class App extends Component {
  state = {
    pasaporte: "",
    anverso: "",
    reverso: "",
    facial: "",
    success: true,
    uuid: "",
    showSpinner: false,
    textSpinner: "Verificando",
    resultado: false,
    documento: "ine",
    datosIne: {
      apellidos: "",
      nombre: "",
      numDoc: "",
      feExp: "",
      feNac: "",
      tipoDoc: "",
      sexo: "",
      nac: "",
      pais: "",
      numPer1: "",
      numPer2: "",
      cveElec: "",
      seccion: "",
      anverso: "",
      reverso: "",
      cara: "",
      facial: ""
    },
    datosPasaporte: {
      apellidos: "",
      nombre: "",
      numDoc: "",
      feExp: "",
      feNac: "",
      lugNac: "",
      tipoDoc: "",
      sexo: "",
      nac: "",
      pais: "",
      pasaporte: "",
      cara: "",
      facial: ""
    },
    checks: {
      totalChecks: "",
      successChecks: "",
      failedCehcks: "",
      warningChecks: ""
    }
  };

  constructor(props) {
    super();
    this.spiner = this.spiner.bind(this);
  }

  getPasaporte = pasaporte => {
    this.setState({ pasaporte: pasaporte.split(",")[1] });
  };

  getAnverso = anverso => {
    this.setState({ anverso: anverso.split(",")[1] });
  };

  getReverso = reverso => {
    this.setState({ reverso: reverso.split(",")[1] });
  };

  getFacial = facial => {
    this.setState({ facial: facial.split(",")[1] });
  };

  verifyIne = () => {
    this.spiner("Verificando, esto puede tardar unos minutos");
    Axios.post("/api/verify", {
      front: this.state.anverso,
      back: this.state.reverso,
      face: this.state.facial
    })
      .then(async response => {
        //console.log(response.data);
        this.setState({ uuid: response.data });

        let status = await fetchRetry("/api/status", {
          retries: 15, // Number of retries, default is 1
          retryDelay: 1000 * 3, // Delay in ms before retrying, default is 1000ms
          // onRetry: ({ retriesLeft, response, responseText }) =>
          //   console.log(retriesLeft, response.status, responseText),
          retryStatus: [500, 400, 401], // Status codes of response that should trigger retry e.g. [500, 404] or just "500".
          // defaults to empty array
          retryUntil: [
            "Checked",
            "ManualIdentification",
            "ManualChecking",
            "ExpertManualIdentification",
            "ExpertManualChecking"
          ],
          timeout: 1000 * 60, // Timeout in ms before throwing a timeout error for the request.
          // Defaults to no timeout (undefined).
          factor: 0.5,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            uuid: this.state.uuid
          })
        });

        if (status.ok) {
          let responseText = await status.text();

          if (responseText === "Checked") {
            Axios.post("/api/results", {
              uuid: this.state.uuid,
              includeImages: true
            })
              .then(response => {
                /*console.log("***********Resultados**********");
              console.log(response);*/
                let { datosIne, checks } = this.state;
                datosIne.apellidos = response.data.documentData[0].value;
                datosIne.nombre = response.data.documentData[1].value;
                datosIne.numDoc = response.data.documentData[2].value;
                datosIne.feExp = response.data.documentData[3].value;
                datosIne.feNac = response.data.documentData[4].value;
                datosIne.tipoDoc = response.data.documentData[5].value;
                datosIne.sexo = response.data.documentData[6].value;
                datosIne.nac = response.data.documentData[7].value;
                // datosIne.pais = response.data.documentData[8].value;
                datosIne.numPer1 = response.data.documentData[8].value;
                datosIne.numPer2 = response.data.documentData[9].value;
                datosIne.cveElec = response.data.documentData[10].value;
                datosIne.seccion = response.data.documentData[11].value;
                datosIne.anverso = response.data.frontImage;
                datosIne.reverso = response.data.backImage;
                datosIne.cara = response.data.documentPortraitImage;
                datosIne.facial = response.data.faceImage;
                checks.totalChecks = response.data.totalChecks;
                checks.successChecks = response.data.successChecks;
                checks.failedChecks = response.data.failedChecks;
                checks.warningChecks = response.data.warningChecks;
                this.setState({
                  resultado: true,
                  datosIne: datosIne,
                  checks: checks
                });
                this.spiner("Verificando, esto puede tardar unos minutos");
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.setState({ resultado: true, success: false });
            this.spiner("Verificando, esto puede tardar unos minutos");
            console.log("no es checked, es " + response.data);
          }
        } else {
          this.setState({ resultado: true, success: false });
          this.spiner("Verificando, esto puede tardar unos minutos");
        }
      })
      .catch(error => {
        this.setState({ resultado: true, success: false });
        console.log(error);
        this.spiner("Verificando, esto puede tardar unos minutos");
      });
  };

  verifyPasaporte = () => {
    this.spiner("Verificando, esto puede tardar unos minutos");
    this.setState({ documento: "pasaporte" });
    Axios.post("/api/verify", {
      front: this.state.pasaporte,
      back: null,
      face: this.state.facial
    })
      .then(response => {
        //console.log(response.data);
        this.setState({ uuid: response.data });

        setTimeout(() => {
          Axios.post("/api/status", {
            uuid: this.state.uuid
          })
            .then(response => {
              //console.log(response.data);
              if (response.data === "Checked") {
                Axios.post("/api/results", {
                  uuid: this.state.uuid,
                  includeImages: true
                })
                  .then(response => {
                    /*console.log("***********Resultados**********");
                    console.log(response);*/
                    let { datosPasaporte, checks } = this.state;
                    datosPasaporte.apellidos =
                      response.data.documentData[0].value;
                    datosPasaporte.nombre = response.data.documentData[1].value;
                    datosPasaporte.numDoc = response.data.documentData[2].value;
                    datosPasaporte.feExp = response.data.documentData[3].value;
                    datosPasaporte.feNac = response.data.documentData[4].value;
                    datosPasaporte.lugNac = response.data.documentData[5].value;
                    datosPasaporte.tipoDoc =
                      response.data.documentData[6].value;
                    datosPasaporte.sexo = response.data.documentData[7].value;
                    datosPasaporte.nac = response.data.documentData[8].value;
                    // datosPasaporte.pais = response.data.documentData[9].value;
                    datosPasaporte.pasaporte = response.data.frontImage;
                    datosPasaporte.cara = response.data.documentPortraitImage;
                    datosPasaporte.facial = response.data.faceImage;
                    checks.totalChecks = response.data.totalChecks;
                    checks.successChecks = response.data.successChecks;
                    checks.failedChecks = response.data.failedChecks;
                    checks.warningChecks = response.data.warningChecks;
                    this.setState({
                      resultado: true,
                      datosPasaporte: datosPasaporte,
                      checks: checks
                    });
                    this.spiner("Verificando, esto puede tardar unos minutos");
                  })
                  .catch(error => {
                    console.log(error);
                  });
              } else {
                this.setState({ resultado: true, success: false });
                this.spiner("Verificando, esto puede tardar unos minutos");
                //console.log("no es checked, es " + response.data);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }, 45000);
      })
      .catch(error => {
        this.setState({ resultado: true, success: false });
        console.log(error);
        this.spiner("Verificando, esto puede tardar unos minutos");
      });
  };

  spiner = texto => {
    let { showSpinner, textSpinner } = this.state;
    textSpinner = texto;
    this.setState({ showSpinner: !showSpinner, textSpinner: textSpinner });
  };

  render() {
    return (
      <div>
        {this.state.showSpinner && (
          <div className="loading">
            <div>
              <div className="c1" />
              <div className="c2" />
              <div className="c3" />
              <div className="c4" />
            </div>

            <span>{this.state.textSpinner}</span>
          </div>
        )}
        <Router>
          <div>
            <Route exact path="/" component={Menu} />
            <Route
              exact
              path="/pasaporte"
              render={() => (
                <React.Fragment>
                  <Pasaporte foto={this.getPasaporte} spiner={this.spiner} />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/anverso"
              render={() => (
                <React.Fragment>
                  <Anverso foto={this.getAnverso} spiner={this.spiner} />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/reverso"
              render={() => (
                <React.Fragment>
                  <Reverso foto={this.getReverso} spiner={this.spiner} />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/facial"
              render={() => (
                <React.Fragment>
                  <Facial
                    foto={this.getFacial}
                    sig={
                      this.state.pasaporte !== ""
                        ? "/resumenpasaporte"
                        : "/resumencredencial"
                    }
                    spiner={this.spiner}
                  />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/resumencredencial"
              render={() => (
                <React.Fragment>
                  <ResumenCredencial
                    anverso={this.state.anverso}
                    reverso={this.state.reverso}
                    facial={this.state.facial}
                    verificar={this.verifyIne}
                  />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/resumenpasaporte"
              render={() => (
                <React.Fragment>
                  <ResumenPasaporte
                    pasaporte={this.state.pasaporte}
                    facial={this.state.facial}
                    verificar={this.verifyPasaporte}
                  />
                </React.Fragment>
              )}
            />
            {this.state.resultado && (
              <Route
                exact
                path="/resultado"
                render={() => (
                  <React.Fragment>
                    <Resultado
                      success={this.state.success}
                      documento={this.state.documento}
                      data={
                        this.state.documento === "ine"
                          ? this.state.datosIne
                          : this.state.datosPasaporte
                      }
                      checks={this.state.checks}
                      uuid={this.state.uuid}
                    />
                  </React.Fragment>
                )}
              />
            )}
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
