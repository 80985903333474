import React, { Component } from "react";
import FileBase64 from "react-file-base64";
import { Link } from "react-router-dom";
import Axios from "axios";
import veridocImg from "../assets/veridoc.png";
import sumaIMG from "../assets/logo.png";

import Camara from "./Camara";

export class Facial extends Component {
  state = {
    showCamera: false,
    showButton: true,
    showFile: false,
    selectedPhoto: "",
    showSelectedPhoto: false,
    showAlert: false,
    type: "FACE",
    secs: 5,
    filtro: "elipse",
    sig: this.props.sig
  };

  tomarFoto = () => {
    const { showCamera, showButton } = this.state;
    this.setState({ showButton: !showButton, showCamera: !showCamera });
  };

  subirFoto = () => {
    const { showFile, showButton } = this.state;
    this.setState({ showButton: !showButton, showFile: !showFile });
  };

  seleccionarFoto = Files => {
    this.setState({ showAlert: false });
    this.props.spiner("Verificando calidad de imagen");
    this.setState({
      selectedPhoto: Files.base64
    });
    Axios.post("/api/quality", {
      type: this.state.type,
      image: this.state.selectedPhoto.split(",")[1]
    })
      .then(response => {
        if (response.data === true) {
          this.props.spiner("Verificando calidad de imagen");
          this.setState({
            showSelectedPhoto: true
          });
          this.props.foto(Files.base64);
        } else {
          this.props.spiner();
          this.setState({ showAlert: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <div className="row header">
          <div className="col-sm-3">
            <a href="/">
              <img
                src={veridocImg}
                alt="logo-veridoc"
                className="titulo-img-left"
              />
            </a>
          </div>
          <div className="col-sm-6" />
          <div className="col-sm-3">
            <a href="https://sumamexico.com/">
              <img src={sumaIMG} alt="logo-suma" className="titulo-img-right" />
            </a>
          </div>
        </div>
        <div className="row titulo justify-content-md-center">
          <div className="col-md-auto">
            <h1 id="titulo">Captura facial</h1>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-md-center">
            {this.state.showButton && (
              <div className="col-md-auto instrucciones" id="instrucciones">
                <p className="instrucciones">
                  **Tomate una foto para la verificación**
                </p>
                <p className="instrucciones">
                  **Alinea tu cara con el ovalo blanco, tienes 5 segundos antes
                  de que se tome la fotografía**
                </p>
              </div>
            )}
            <div className="col-md-auto">
              {this.state.showCamera && (
                <Camara
                  secs={this.state.secs}
                  filtro={this.state.filtro}
                  sig={this.state.sig}
                  foto={this.props.foto}
                  otra={this.tomarFoto}
                  type={this.state.type}
                  spiner={this.props.spiner}
                />
              )}
              {this.state.showFile && (
                <div style={{ marginTop: "15px" }}>
                  <div className="row">
                    <FileBase64
                      multiple={false}
                      onDone={this.seleccionarFoto}
                    />
                  </div>
                  {this.state.showSelectedPhoto && (
                    <div>
                      <img
                        src={this.state.selectedPhoto}
                        alt="FotoSeleccionada"
                        style={{ maxWidth: "450px", marginTop: "15px" }}
                      />
                      <br />
                      <Link to={this.state.sig}>
                        <button
                          id="siguiente"
                          className="btn btn-outline-success btn-lg photo"
                          style={{ float: "right" }}>
                          Siguiente
                        </button>
                      </Link>
                    </div>
                  )}
                  {this.state.showAlert && (
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert">
                        <h4 className="alert-heading">Mala calidad</h4>
                        <p>
                          Las imágenes tienen que ser nítidas, claras y procura
                          que las fotos no estén desalineadas, puedes
                          seleccionar otra foto
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <button
                      id="otroArchivo"
                      className="btn btn-outline-secondary btn-lg photo"
                      onClick={this.subirFoto}>
                      Cancelar
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-auto camera">
              {this.state.showButton && (
                <div>
                  <div className="row justify-content-md-center">
                    <button
                      id="anversoFoto"
                      className="btn btn-outline-primary btn-lg boton"
                      onClick={this.tomarFoto}>
                      Tomar fotografía
                    </button>
                  </div>
                  <div className="row justify-content-md-center">
                    <p className="instrucciones">
                      O bien selecciona un archivo
                    </p>
                  </div>
                  <div className="row justify-content-md-center">
                    <button
                      id="seleccionarAnversoFoto"
                      className="btn btn-outline-primary btn-lg boton"
                      onClick={this.subirFoto}>
                      Seleccionar Archivo
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Facial;
