import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";

export class Camara extends Component {
  state = {
    secs: this.props.secs,
    showCamera: true,
    showPhoto: false,
    showAlert: false,
    foto: this.props.foto
  };

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    this.props.spiner("Verificando calidad de imagen");
    let { foto, showPhoto, showCamera } = this.state;
    foto = this.webcam.getScreenshot();
    this.setState({
      foto: foto,
      showCamera: !showCamera
    });

    Axios.post("/api/quality", {
      type: this.props.type,
      image: this.state.foto.split(",")[1]
    })
      .then(response => {
        if (response.data === true) {
          this.props.spiner("Verificando calidad de imagen");
          this.setState({
            showPhoto: !showPhoto
          });
          this.props.foto(foto);
        } else {
          this.props.spiner();
          this.setState({ showAlert: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.countdownTimer = setInterval(() => {
      let { secs } = this.state;
      if (secs === 0) {
        this.capture();
        clearInterval(this.countdownTimer);
      }
      this.setState({ secs: secs <= 0 ? 0 : secs - 1 });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.countdownTimer);
  }

  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: "user"
    };
    let secs = this.state.secs;
    return (
      <div>
        {this.state.showCamera && (
          <div>
            <div id="underground">
              <h1 className="timer" id="countdown">
                {secs + " "} segundos
              </h1>
            </div>
            <div id="overlay" className="camera">
              <div id={this.props.filtro} />
            </div>
            <Webcam
              audio={false}
              id="video"
              width="950"
              height="640"
              autoplay="false"
              ref={this.setRef}
              screenshotFormat="image/png"
              videoConstraints={videoConstraints}
              style={{ marginTop: "-15px" }}
            />
          </div>
        )}
        {this.state.showPhoto && (
          <div>
            <img src={this.state.foto} alt="Captura" className="photo" />
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-md-3 offset-md-3">
                <button
                  id="otraFoto"
                  className="btn btn-outline-secondary btn-lg photo"
                  onClick={this.props.otra}>
                  Tomar otra fotografía
                </button>
              </div>
              <div className="col-md-3 offset-md-1">
                <Link to={this.props.sig}>
                  <button
                    id="siguiente"
                    className="btn btn-outline-success btn-lg photo">
                    Siguiente
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
        {this.state.showAlert && (
          <div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading">Mala calidad</h4>
                <p>
                  Las imágenes tienen que ser nítidas, claras y procura que las
                  fotos no estén desalineadas, puedes tomar otra foto.
                </p>
                <p>
                  En el caso de que tu computadora no tenga una buena cámara es
                  más recomendable que se seleccione un archivo
                </p>
              </div>
            </div>
            <div className="row">
              <button
                id="otraFoto"
                className="btn btn-outline-secondary btn-lg photo"
                onClick={this.props.otra}>
                Tomar otra fotografía
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Camara;
