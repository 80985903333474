import React, { Component } from "react";
import veridocImg from "../assets/veridoc.png";
import sumaIMG from "../assets/logo.png";
import id from "../assets/id-card.png";
import pasaporte from "../assets/passport.png";

export default class Menu extends Component {
  render() {
    return (
      <div>
        <div className="row header">
          <div className="col-sm-3">
            <a href="/">
              <img
                src={veridocImg}
                alt="logo-veridoc"
                className="titulo-img-left"
              />
            </a>
          </div>
          <div className="col-sm-6" />
          <div className="col-sm-3">
            <a href="https://sumamexico.com/">
              <img src={sumaIMG} alt="logo-suma" className="titulo-img-right" />
            </a>
          </div>
        </div>
        <div className="row titulo justify-content-md-center">
          <div className="col-md-auto">
            <h1>Elige el documento de tu preferencia.</h1>
          </div>
        </div>
        <div className="container">
          <div className="row" style={{ marginTop: "35px" }}>
            <div className="col-md-6">
              <a href="./anverso" className="link hvr-grow">
                <div className="text-center">
                  <img src={id} alt="ine" className="documento" />
                  <h2 style={{ marginTop: "20px" }}>Credencial de identidad</h2>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <a href="./pasaporte" className="link hvr-grow">
                <div className="text-center">
                  <img src={pasaporte} alt="pass" className="documento" />
                  <h2 style={{ marginTop: "20px" }}>Pasaporte</h2>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
