import React, { Component } from "react";
import { Link } from "react-router-dom";
import veridocImg from "../assets/veridoc.png";
import sumaIMG from "../assets/logo.png";

export class ResumenCredencial extends Component {
  render() {
    return (
      <div>
        <div className="row header">
          <div className="col-sm-3">
            <a href="/">
              <img
                src={veridocImg}
                alt="logo-veridoc"
                className="titulo-img-left"
              />
            </a>
          </div>
          <div className="col-sm-6" />
          <div className="col-sm-3">
            <a href="https://sumamexico.com/">
              <img src={sumaIMG} alt="logo-suma" className="titulo-img-right" />
            </a>
          </div>
        </div>
        <div className="row titulo justify-content-md-center">
          <div className="col-md-auto">
            <h1 id="titulo">Capturas obtenidas</h1>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center">
              <p className="instrucciones">Anverso</p>
              <img
                src={"data:image/png;base64," + this.props.anverso}
                alt="anverso"
                style={{ maxWidth: "350px" }}
              />
            </div>
            <div className="col-md-4 text-center">
              <p className="instrucciones">Reverso</p>
              <img
                src={"data:image/png;base64," + this.props.reverso}
                alt="reverso"
                style={{ maxWidth: "350px" }}
              />
            </div>
            <div className="col-md-4 text-center">
              <p className="instrucciones">Facial</p>
              <img
                src={"data:image/png;base64," + this.props.facial}
                alt="facial"
                style={{ maxWidth: "350px" }}
              />
            </div>
          </div>

          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-3 offset-md-3">
              <a href="/">
                <button className="btn btn-outline-secondary btn-lg photo">
                  Reiniciar captura
                </button>
              </a>
            </div>
            <div className="col-md-3 offset-md-1">
              <Link to="/resultado">
                <button
                  id="siguiente"
                  className="btn btn-outline-success btn-lg photo"
                  onClick={this.props.verificar}>
                  Verificar
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResumenCredencial;
